import { Action, ActionType, createAsyncAction, createReducer } from 'typesafe-actions';
import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { produce } from 'immer';
import Boom from '@hapi/boom';
import { ajax } from 'rxjs/ajax';
import { TypedEpic } from '../types';

// Actions
export enum Actions {
  GET_CATEGORIES = 'GET_CATEGORIES',
  GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL',
  GET_CATEGORIES_CANCEL = 'GET_CATEGORIES_CANCEL',
}

export interface Category {
  slug: string;
  name: string;
}

export interface CategoryListResponse {
  categories: Category[];
}

export interface State {
  data: {
    categoryData: CategoryListResponse;
    isLoading: boolean;
    error?: Error;
  };
}

export const initialState: State = {
  data: {
    categoryData: { categories: [] },
    isLoading: false,
  },
};

export const actions = {
  getCategories: createAsyncAction(
    Actions.GET_CATEGORIES,
    Actions.GET_CATEGORIES_SUCCESS,
    Actions.GET_CATEGORIES_FAIL,
    Actions.GET_CATEGORIES_CANCEL,
  )<{ categories?: string[]; simpleToken?: string | null } | null, CategoryListResponse, Error, void>(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.getCategories.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.data.isLoading = true;
      draftState.data.error = undefined;
    }),
  )
  .handleAction(actions.getCategories.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.data.isLoading = false;
      draftState.data.categoryData = action.payload;
    }),
  )
  .handleAction(actions.getCategories.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.data.isLoading = false;
      draftState.data.error = action.payload;
    }),
  )
  .handleAction(actions.getCategories.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.data.isLoading = false;
    }),
  );

const fetchCategoriesEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimNafNoApi, apimContentHub } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_CATEGORIES),
    switchMap((action: ActionType<typeof actions.getCategories.request>) => {
      // Create a comma-separated list of category slugs if provided
      const categoriesParam =
        action.payload?.categories && action.payload.categories.length > 0
          ? `categories=${action.payload.categories.join(',')}`
          : '';

      const url = `${apimBaseUrl}/${apimNafNoApi}/articles/categories${categoriesParam ? `?${categoriesParam}` : ''}`;

      return ajax<CategoryListResponse>({
        url,
        headers: {
          'Ocp-Apim-Subscription-Key': apimContentHub,
        },
      }).pipe(
        map(({ response }) => actions.getCategories.success(response)),
        catchError(() => of(actions.getCategories.failure(new Boom.Boom('Could not get categories')))),
      );
    }),
  );
};

export const epics: TypedEpic[] = [fetchCategoriesEpic];
