import React from 'react';
import { AdvancedImage, lazyload, responsive } from '@cloudinary/react';
import { TextVariant } from '@naf/text';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { useDocumentUrlWithNode } from '../../../../hooks/useDocumentUrl';
import { useCloudinary } from '../../../../hooks/Cloudinary/useCloudinary';
import { InternalMainEntry } from '../../../../../../types/InternalMainEntryType';
import { ExternalMainEntry } from '../../../../../../types/ExternalMainEntryType';
import { StyledA, Card, ImageWrapper, Label, StyledText, StyledLink, UnderlinedElement } from './Styles';

export interface ExternalMainEntryCardProps {
  externalMainEntry: ExternalMainEntry;
}

export interface InternalMainEntryCardProps {
  internalMainEntry: InternalMainEntry;
}

export const ExternalMainEntryCard = ({ externalMainEntry: { image, data } }: ExternalMainEntryCardProps) => {
  const cld = useCloudinary();

  const mainImage =
    (image && cld.image(image.publicId).delivery(dpr('3.0')).quality('auto:best').format('svg')) || undefined;

  return (
    <StyledA href={data.href}>
      <Card>
        {mainImage && (
          <ImageWrapper>
            <AdvancedImage
              cldImg={mainImage}
              plugins={[lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 }), responsive({ steps: 200 })]}
              alt={image.altText || image.caption}
            />
          </ImageWrapper>
        )}
        <Label>
          <StyledText variant={TextVariant.Header3}>
            <UnderlinedElement>{data.title}</UnderlinedElement>
          </StyledText>
        </Label>
      </Card>
    </StyledA>
  );
};

export const InternalMainEntryCard = ({ internalMainEntry: { image, data } }: InternalMainEntryCardProps) => {
  const url = useDocumentUrlWithNode(data) || '';

  const cld = useCloudinary();

  const mainImage =
    (image && cld.image(image.publicId).delivery(dpr('3.0')).quality('auto:best').format('svg')) || undefined;

  return (
    <StyledLink to={url}>
      <Card>
        {mainImage && (
          <ImageWrapper>
            <AdvancedImage
              cldImg={mainImage}
              alt={image.altText || image.caption}
              plugins={[responsive({ steps: 200 }), lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 })]}
            />
          </ImageWrapper>
        )}
        <Label>
          <StyledText variant={TextVariant.CardHeader}>
            <UnderlinedElement>{data.name}</UnderlinedElement>
          </StyledText>
        </Label>
      </Card>
    </StyledLink>
  );
};
