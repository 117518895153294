import { Text } from '@naf/text';
import { spacing, breakpoints } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  justify-self: center;
  flex-direction: column;
  padding: ${spacing.space20} 0 0 0;
  flex: 1 1 0;
  width: ${spacing.space120};
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${spacing.space120};
  height: ${spacing.space120};
  border-radius: 52px;
`;

export const UnderlinedElement = styled.span`
  background-size: 0 2px;
  background-position: 0 100%;
  background-image: linear-gradient(90deg, #ffdb16, #ffdb16);
  background-repeat: no-repeat;
  -webkit-transition: background-size 0.3s ease;
  transition: background-size 0.3s ease;
  -webkit-text-decoration: none;
  text-decoration: none;
  display: inline;
`;

export const StyledLink = styled(Link)`
  display: flex;
  text-decoration: none;

  // Create padding to display focus line
  padding: 0 ${spacing.space20};

  * img {
    max-width: ${spacing.space120};
    height: ${spacing.space120};
    transition: transform 0.3s ease;
    @media (min-width: ${breakpoints.m}) {
      align-self: center;
    }
  }

  &:hover {
    * img {
      transform: scale(1.1);
    }
  }

  :hover {
    color: inherit;
  }
  &:hover ${UnderlinedElement}, &:active ${UnderlinedElement} {
    background-size: 100% 2px;
  }
  &:active {
    outline: none !important;
  }
`;

export const StyledA = styled.a`
  display: flex;
  text-decoration: none;

  // Create padding to display focus line
  padding: 0 ${spacing.space20};

  * img {
    max-width: 120px;
    transition: transform 0.3s ease;
    @media (min-width: ${breakpoints.m}) {
      align-self: center;
    }
  }

  &:hover {
    color: ${nafColor.signature.black};
    * img {
      transform: scale(1.1);
    }
  }
  &:hover ${UnderlinedElement}, &:active ${UnderlinedElement} {
    background-size: 100% 2px;
  }
  &:active {
    outline: none !important;
  }
`;

export const Label = styled.div`
  display: flex;
  justify-content: center;
  width: 132px;
`;

export const StyledText = styled(Text)`
  margin-top: ${spacing.space12};
  margin-bottom: 0;
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.5;

  @media (min-width: ${breakpoints.l}) {
    font-size: 1.5rem;
  }
  @media (max-width: ${breakpoints.m}) {
    margin-top: ${spacing.space8};
  }
`;
