import styled from 'styled-components';
import { Text } from '@naf/text';
import { nafColor } from '@nafcore/theme';
import { spacing } from '@naf/theme';

export const Title = styled(Text)`
  margin: 0;
  margin-bottom: ${spacing.space16};
`;

export const Ingress = styled(Text)`
  margin: 0;
  margin-bottom: ${spacing.space16};
`;

export const Container = styled.div<{ $variant?: string; $onlyTitles?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing.space32};

  * > a {
    color: ${nafColor.primary.park};
  }

  ol {
    list-style: none;
    counter-reset: item;
    margin-top: ${spacing.space48};
    margin-left: 0;
    padding-left: 60px;
    margin-bottom: -${spacing.space48};
    max-width: 550px;
  }

  li {
    counter-increment: item;
    position: relative;
    margin-bottom: ${({ $onlyTitles }) => ($onlyTitles ? '35px' : '24px')};

    p {
      position: relative;
      top: -36px;
      vertical-align: middle;
      display: inline-block;
    }
  }

  li:before {
    position: absolute;
    left: -59px;
    top: -41.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    width: 38px;
    border-radius: ${spacing.space20};
    margin-right: 10px;
    margin-bottom: ${spacing.space8};
    content: counter(item);
    color: #062e2a;
    text-align: center;
    font-weight: 900;

    ${({ $variant }) =>
      $variant === 'outline' &&
      `
      background: ${nafColor.signature.white};
      border: 1px solid black;
    `}

    ${({ $variant }) =>
      $variant === 'secondary' &&
      `
      background: #d3eac2;
    `}
  }

  li:not(:last-child):after {
    content: '';
    position: absolute;
    left: -41px;
    top: -5px;
    bottom: 0;
    height: calc(100% - 5px);
    width: 3px;
    background-image: linear-gradient(to bottom, #062e2a 33%, rgba(255, 255, 255, 0) 0%);
    background-size: 3px 10px;
    background-repeat: repeat-y;
    z-index: -1;
  }
`;

export const Step = styled(Text)`
  margin: 0;
  font-weight: 500;
  font-size: 1.125rem;
`;

export const ListContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BodyWrapper = styled.div`
  p {
    position: relative;
    top: -50px !important;
    vertical-align: middle;
    display: inline-block;
  }
`;
