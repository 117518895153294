import { NAFGTMEvent } from '../../../../types/GTM/NAFGTMEvent';
import { useGTMDataLayer } from './useGTMDataLayer';

export const useGTMTrackAuthState = () => {
  const datalayer = useGTMDataLayer();

  const trackLogin = () => {
    if (!datalayer) return;

    datalayer.push({
      event: NAFGTMEvent.login,
    });
  };

  const trackLogout = () => {
    if (!datalayer) return;

    datalayer.push({
      event: NAFGTMEvent.logout,
    });
  };

  return {
    trackLogin,
    trackLogout,
  };
};
