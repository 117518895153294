import React from 'react';
import { Text, TextVariant } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import styled from 'styled-components';
import { ExternalLinkCard } from './ExternalLinkCard';
import { InternalLinkCard } from './InternalLinkCard';
import { ExternalLinkType } from '../../../../../../types/externalLinkType';
import { InternalLinkType } from '../../../../../../types/internalLinkType';

interface ShortcutsBlockProps {
  node?: ShortcutsProps;
}

export interface ShortcutsProps {
  key: string;
  shortcutList: (
    | { data: ExternalLinkType; type: 'externalLink'; key: string }
    | { data: InternalLinkType; type: 'internalLink'; key: string }
  )[];
  type: 'shortcuts';
  hideTitle?: boolean;
}

export const Shortcuts = ({ node: { shortcutList, hideTitle } }: ShortcutsBlockProps) => (
  <ShortcutsContainer>
    {hideTitle ? null : <Text variant={TextVariant.Header2}>Populære tjenester fra NAF</Text>}
    <StyledFlexWrapper>
      {shortcutList &&
        shortcutList?.map((e) => {
          switch (e.type) {
            case 'externalLink':
              return <ExternalLinkCard externalLink={e.data} key={e.key} />;
            case 'internalLink':
              return <InternalLinkCard internalLink={e.data} key={e.key} />;
            default:
              return null;
          }
        })}
    </StyledFlexWrapper>
  </ShortcutsContainer>
);

const StyledFlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.space32};
  & > div {
    flex-basis: 22rem;
    flex-grow: 1;
  }
  @media (max-width: ${breakpoints.m}) {
    gap: ${spacing.space24};
  }
`;
export const ShortcutsContainer = styled.div`
  margin-top: 0;
`;
